// Add comments here if needed
/* You can add global styles to this file, and also import other style files */
// This is our global style
@import '_dark-mode';
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
small {
  font-size: 13px;
}

:root {
  --seal-pos: flex-end;
}
.custom-size {
  font-size: 9px;
  width: 10px;
  height: 10px;
}
span.cursor-pointer {
  font-size: 12px;
  cursor: pointer;
}
tr td:first-child {
  width: 30%;
}
.round-button {
  position: relative;
  top: 20px; /* Adjust the top position as needed */
  left: 20px; /* Adjust the left position as needed */
  z-index: 1000; /* Ensure the button appears above other content */
  width: 40px; /* Set the width of the button */
  height: 40px; /* Set the height of the button */
  background-color: #0d6efd; /* Set the background color of the button */
  border-radius: 50%; /* Make the button round */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white; /* Set the color of the text/icon inside the button */
  border: none;
}
.round-button:hover {
  background-color: #0056b3; /* Change the background color on hover */
}
#seal-loader {
  float: right;
  margin-right: 20px;
}
html {
  height: 100%;
  margin: 0;
}
.is-deposit {
  border-left: 2px solid red;
}
body {
  font-family: 'Open Sans', sans-serif;
}
#siteseal {
  display: flex;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: var(--seal-pos);
}
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100vh;
}
.done {
  width: 80px;
}
.content {
  flex: 1; /* Take up remaining vertical space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 75px;
}
.bg-custom {
  background-color: rgb(56 59 66);
}
.w-custom {
  width: 202px;
}
.img-mobile {
  margin-top: 50px;
  width: 158px;
}
.p-1-cust {
  padding: 1px;
}
i.bi:not(
    .bi-moon,
    .bi-brightness-high,
    .bi-arrow-left-right,
    .bi-exclamation-octagon,
    .bi-check2-circle,
    .bi-arrow-clockwise,
    .bi-info-circle
  ) {
  height: 17px;
  top: 4px;
  position: absolute;
  left: 10px;
}

.dialog-header {
  padding: 10px 1px;
  border-bottom: 1px solid #d2d4d8;
}
.input-group-text {
  width: 35px;
}
.card {
  background-color: transparent !important;
  border: 1px solid $borderColorLight;
}
// Mobile
@media only screen and (max-width: 767px) {
  .d-mobile {
    text-align: center;
  }
  .h-100-mobile {
    width: 100%;
  }
  .mobile {
    width: 100% !important;
    padding: 10px !important;
  }
  .img-mobile {
    display: none !important;
  }
  #siteseal {
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-right: 0;
    justify-content: center;
  }
  .sec-div {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.p-dialog-content {
  padding: 0 !important;
  border-radius: 6px;
  overflow-y: unset !important;
  &.primeng-dialog {
    border-radius: 0;
    overflow-y: auto !important;
  }
}
.dialog-body {
  margin: 10px;
}
.dialog-footer {
  text-align: center;
  margin-bottom: 10px;
  & > button {
    margin-right: 10px;
  }
}
