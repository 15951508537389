@import '_colors';

[data-dark-mode='dark-mode'] {
  body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    color: $fontColor;
  }
  .rc-anchor-dark-md {
    background: $bgColor !important;
    color: $fontColor !important;
  }
  .bg-light {
    background-color: $bgColor !important;
  }
  .encore-logo {
    mix-blend-mode: difference;
  }
  label,
  small {
    color: $fontColor;
  }
  ::placeholder {
    color: $fontColor;
  }
  .card {
    background-color: transparent !important;
    border: 1px solid $borderColor;
  }
  .nav-tabs .nav-link.active {
    border: 1px solid $borderColor;
    background-color: $bgColor;
    color: $fontColor;
  }
  .form-control {
    color: $fontColor;
    background-color: $bgColor;
    border: 1px solid $borderColor;
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .input-group-text {
    background-color: $bgColor;
    border: 1px solid $borderColor;
    color: white;
  }
  .nav-tabs {
    border-bottom: 1px solid $borderColor;
  }
  :disabled {
    opacity: 0.6;
  }
  select:disabled {
    background-color: $bgColor; /* Match the background color of the select box */
    color: $fontColor; /* Set text color for option elements */
    border: 1px solid $borderColor;
  }
  select {
    background-color: $bgColor; /* Match the background color of the select box */
    color: $fontColor; /* Set text color for option elements */
    border: 1px solid $borderColor;
  }
  select option {
    background-color: $bgColor; /* Match the background color of the select box */
    color: $fontColor; /* Set text color for option elements */
  }
  .list-group,
  .list-group-item {
    border-color: $borderColor;
  }

  .list-group .bg-light {
    color: $fontColor;
  }
  .border-radious-5 {
    border: 1px solid $borderColor !important;
    border-bottom: 0 !important;
  }
  .p-dialog-content {
    color: $fontColor !important;
    background: $bgColor !important;
  }
  table#order-details {
    border-color: $borderColor;
  }
  .table * {
    color: $fontColor !important;
  }
  .alert-warning {
    color: #fddd77;
    background-color: #332806;
    border-color: #332806;
  }

  .alert-success {
    color: #75b798;
    background-color: #051b11;
    border-color: #0f5132;
  }

  .alert-danger {
    color: #ea868f;
    background-color: #2c0b0e;
    border-color: #842029;
  }

  .alert-info {
    color: #6edff6;
    background-color: #032830;
    border-color: #087990;
  }
  .alert-light {
    color: #3562f6;
    background-color: #181c28;
    border-color: #087990;
  }
}
